@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-backgroundPrimary: 40 33 40;
    --color-backgroundSecondary: 20 20 20;
    --color-textPrimary: 255 255 255;
    --color-textSecondary: 143 143 143;
    --color-buttonPrimary: 152 92 166;
    --color-progressBarColor: 25 128 56;
    --color-inputBackgroundColor: 255 255 255;
    --color-inputTextColor: 20 20 20;
    --size-progressBarHeight: 24px;
    --color-errorColor: 255 64 64;
    --color-warningColor: 255 151 0;
    --color-successColor: 25 128 56;
    --color-buttonSecondary: 216 216 216;
    --font-fontFamily: Roboto, sans-serif;

    /* tailwind vars */
    --antd-colorBgBase: 20 20 20;
    --antd-colorBgBlur: 255 255 255 0.04;
    --antd-colorBgContainer: 40 33 40;
    --antd-colorBgContainerDisabled: 255 255 255 0;
    --antd-colorBgElevated: 51 51 51;
    --antd-colorBgLayout: 20 20 20;
    --antd-colorBgMask: 0 0 0 0.45;
    --antd-colorBgSpotlight: 86 86 86 0.18;
    --antd-colorBgTextActive: 255 255 255 0.8;
    --antd-colorBgTextHover: 255 255 255 0.12;
    --antd-colorBorder: 86 86 86;
    --antd-colorBorderBg: 40 33 40;
    --antd-colorBorderSecondary: 68 68 68;
    --antd-colorError: 220 57 57;
    --antd-colorErrorActive: 173 49 49;
    --antd-colorErrorBg: 42 21 23;
    --antd-colorErrorBgHover: 69 26 27;
    --antd-colorErrorBorder: 91 33 33;
    --antd-colorErrorBorderHover: 126, 40, 40;
    --antd-colorErrorHover: 232, 101, 97;
    --antd-colorErrorOutline: 50 4 0 0.42;
    --antd-colorErrorText: 220, 57, 57;
    --antd-colorErrorTextActive: 173, 49, 49;
    --antd-colorErrorTextHover: 232, 101, 97;
    --antd-colorFill: 255 255 255 0.18;
    --antd-colorFillAlter: 255 255 255 0.04;
    --antd-colorFillContent: 255 255 255 0.12;
    --antd-colorFillContentHover: 255 255 255 0.18;
    --antd-colorFillQuaternary: 255 255 255 0.04;
    --antd-colorFillSecondary: 255 255 255 0.12;
    --antd-colorFillTertiary: 255 255 255 0.08;
    --antd-colorIcon: 255 255 255 0.45;
    --antd-colorIconHover: 255 255 255 0.85;
    --antd-colorInfo: 132, 81, 144;
    --antd-colorInfoActive: 106, 67, 115;
    --antd-colorInfoBg: 29, 23, 30;
    --antd-colorInfoBgHover: 44, 31, 47;
    --antd-colorInfoBorder: 60, 42, 80;
    --antd-colorInfoHover: 60, 42, 80;
    --antd-colorInfoText: 132, 81, 144;
    --antd-colorInfoTextActive: 106, 67, 115;
    --antd-colorInfoTextHover: 156, 116, 163;
    --antd-colorLink: 132, 81, 144;
    --antd-colorLinkActive: 106, 67, 115;
    --antd-colorLinkHover: 79, 52, 86;
    --antd-colorPrimary: 132, 81, 144;
    --antd-colorPrimaryActive: 106, 67, 115;
    --antd-colorPrimaryBg: 29, 23, 30;
    --antd-colorPrimaryBgHover: 44, 31, 47;
    --antd-colorPrimaryBorder: 60, 42, 64;
    --antd-colorPrimaryBorderHover: 79, 52, 86;
    --antd-colorPrimaryHover: 156, 116, 163;
    --antd-colorPrimaryText: 132, 81, 144;
    --antd-colorPrimaryTextActive: 106, 67, 115;
    --antd-colorPrimaryTextHover: 156, 116, 163;
    --antd-colorSplit: 215 252 215 0.16;
    --antd-colorSuccess: 24, 112, 50;
    --antd-colorSuccessActive: 23, 90, 43;
    --antd-colorSuccessBg: 18, 25, 20;
    --antd-colorSuccessBgHover: 18, 37, 25;
    --antd-colorSuccessBorder: 22, 52, 21;
    --antd-colorSuccessBorderHover: 22, 69, 36;
    --antd-colorSuccessHover: 22, 69, 36;
    --antd-colorSuccessText: 24, 112, 50;
    --antd-colorSuccessTextActive: 23, 90, 43;
    --antd-colorSuccessTextHover: 47, 128, 69;
    --antd-colorText: 255 255 255 0.85;
    --antd-colorTextBase: 255 255 255;
    --antd-colorTextDescription: 255 255 255 0.45;
    --antd-colorTextDisabled: 255 255 255 0.25;
    --antd-colorTextHeading: 255 255 255 0.85;
    --antd-colorTextLabel: 255 255 255 0.65;
    --antd-colorTextLightSolid: 255 255 255;
    --antd-colorTextPlaceholder: 255 255 255 0.25;
    --antd-colorTextQuaternary: 255 255 255 0.25;
    --antd-colorTextSecondary: 255 255 255 0.65;
    --antd-colorTextTertiary: 255 255 255 0.45;
    --antd-colorWarning: 220, 130, 3;
    --antd-colorWarningActive: 173, 104, 7;
    --antd-colorWarningBg: 44, 31, 17;
    --antd-colorWarningBgHover: 69, 46, 15;
    --antd-colorWarningBorder: 91, 59, 14;
    --antd-colorWarningBorderHover: 126, 78, 11;
    --antd-colorWarningHover: 126, 78, 11;
    --antd-colorWarningOutline: 47 29 0 0.57;
    --antd-colorWarningText: 220, 131, 3;
    --antd-colorWarningTextActive: 173, 104, 7;
    --antd-colorWarningTextHover: 232, 161, 39;
    --antd-colorWhite: 255 255 255;
  }

  * {
    box-sizing: border-box;
  }

  body {
    @apply text-colorTextBase;
    @apply bg-colorBgBase;
    @apply relative;
    font-family: var(--font-fontFamily);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-colorTextBase;
    font-family: var(--font-fontFamily);
  }

  .phantom-quill-editor,
  .phantom-waiver-form {
    background-color: white;
    color: black;
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: black;
    }
  }
}

@media print {
  html {
    @apply text-[#000000];
    @apply bg-[#FFFFFF];
  }
  body {
    @apply text-[#000000];
    @apply bg-[#FFFFFF];
  }
}

html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: 100%;
}

.ant-dropdown-menu-item {
  padding: 8px 10px !important;
}

#nprogress .bar {
  @apply bg-colorPrimary;
  height: 2px;
  z-index: 99999;
}

#nprogress .peg {
  @apply shadow-colorPrimary;
  box-shadow:
    0 0 10px var(--tw-shadow-color),
    0 0 5px var(--tw-shadow-color);
}

.pac-item:hover {
  cursor: pointer;
  @apply bg-gray-200;
}
